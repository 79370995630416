<template>
  <div class="container">
    <el-radio-group v-model="radioValue"
                    :disabled="isHistory">
      <el-radio :label="1">一级表单</el-radio>
      <el-radio :label="2">二级表单</el-radio>
    </el-radio-group>
    <div v-if="radioValue === 1">
      <el-button type="primary"
                 size="mini"
                 class="mt-15"
                 @click="addField">新增字段</el-button>
      <div draggable="true"
           @dragstart.stop="onDragStart(index)"
           @dragover.stop="onDragover(index)"
           @dragend.stop="onDragend(index)"
           v-for="(item,index) in register_info"
           :key="index"
           class="form-item mt-15"
           :style="changeIndex===index?dragBorder:''">
        <i class="el-icon-s-operation"></i>
        <span class="form-name"
              v-if="!item.edit">{{item.name}}</span>
        <el-input v-else
                  v-model="item.name"
                  style="width: 300px"
                  class="mr-15 ml-15" />
        <el-checkbox v-model="register_info[index].show"
                     v-if="item.type!='input'"></el-checkbox>
        <template v-if="item.type === 'input'">
          <el-button type="primary"
                     size="mini"
                     class="mr-15"
                     @click="editField(index)">{{item.edit?'完成':'编辑'}}</el-button>
          <el-button type="primary"
                     size="mini"
                     class="mr-15"
                     @click="delField(index)">删除</el-button>
        </template>
      </div>
    </div>
    <div v-if="radioValue === 2"
         class="tree">
      <el-button type="primary"
                 size="mini"
                 class="add-btn"
                 @click="addField()">新增一级字段</el-button>
      <div class="tree-item"
           draggable="true"
           v-for="(item,index) in fieldList"
           :key="index"
           :style="changeIndex===index?dragBorder:''"
           @dragstart="onDragStart(index)"
           @dragover="onDragover(index)"
           @dragend="onDragend(index)">
        <div class="tree-field-name">
          <i class="el-icon-s-operation mr-15"></i>
          <span class="mr-15"
                v-if="!item.edit">{{item.name}}</span>
          <el-input v-else
                    v-model="item.name"
                    style="width: 300px"
                    class="mr-15 ml-15" />
          <el-button type="primary"
                     size="mini"
                     class="mr-15"
                     @click="addField(index)">新增二级字段</el-button>
          <el-button type="primary"
                     size="mini"
                     class="mr-15"
                     @click="editField(index)">{{item.edit?'完成':'编辑'}}</el-button>
          <el-button type="primary"
                     size="mini"
                     @click="delField(index)"
                     v-if="fieldList.length!=1">删除</el-button>
        </div>
        <div class="tree-children-content">
          <div draggable="true"
               v-for="(item2,index2) in item.child"
               :key="index2"
               class="tree-children-item"
               @dragstart.stop="onChildDragStart(index,index2)"
               @dragover.stop="onChildDragover(index,index2)"
               @dragend.stop="onChildDragend(index2)"
               :style="(changeChildIndex===index2 && dragFatherIndex === index)?dragChildBorder:''">
            <i class="el-icon-s-operation mr-15"></i>
            <span class="form-name mr-15"
                  v-if="!item2.edit">{{item2.name}}</span>
            <el-input v-else
                      v-model="item2.name"
                      style="width: 300px"
                      class="mr-15 ml-15" />
            <el-checkbox v-model="fieldList[index].child[index2].show"
                         v-if="item2.type!='input'"></el-checkbox>
            <template v-if="item2.type === 'input'">
              <el-button type="primary"
                         size="mini"
                         class="mr-15"
                         @click="editField(index,index2)">{{item2.edit?'完成':'编辑'}}</el-button>
              <el-button type="primary"
                         size="mini"
                         class="mr-15"
                         @click="delField(index,index2)">删除</el-button>
            </template>
          </div>
        </div>
      </div>
    </div>
    <el-button type="primary"
               class="mt-15"
               size="mini"
               @click="submit()">保存</el-button>
  </div>
</template>

<script>
import { getSendPacketFormConfig, setSendPacketFormConfig } from '@/api/market'
export default {
  data () {
    return {
      isHistory: false,
      dragFatherIndex: '',
      dragChildIndex: '',
      changeChildIndex: '',
      changeIndex: '',
      dragIndex: '',
      radioValue: 1,
      defalutField: [
        {
          name: '姓名',
          type: 'name',
          is_must: true,
          show: false,
          column: 'name'
        },
        {
          name: '手机号',
          type: 'tel',
          is_must: true,
          show: false,
          column: 'tel'
        },
        {
          name: '授权手机号',
          type: 'register_mini_tel',
          is_must: true,
          show: false,
          column: 'register_mini_tel'
        },
        {
          name: '生日',
          type: 'register_birthday',
          is_must: true,
          show: false,
          column: 'register_birthday'
        },
        {
          name: '所在区域',
          type: 'register_area',
          is_must: true,
          show: false,
          column: 'register_area'
        },
        {
          name: '已完成一次有效交易',
          type: 'is_trading',
          is_must: true,
          show: false,
          column: 'is_trading'
        },
        {
          name: '金额',
          type: 'fixed_money',
          is_must: true,
          show: false,
          column: 'fixed_money'
        }
      ],
      register_info: [
        {
          name: '姓名',
          type: 'name',
          show: false,
          is_must: true,
          column: 'name'
        },
        {
          name: '手机号',
          type: 'tel',
          show: false,
          is_must: true,
          column: 'tel'
        },
        {
          name: '授权手机号',
          show: false,
          type: 'register_mini_tel',
          is_must: true,
          column: 'register_mini_tel'
        },
        {
          name: '生日',
          type: 'register_birthday',
          show: false,
          is_must: true,
          column: 'register_birthday'
        },
        {
          name: '所在区域',
          type: 'register_area',
          show: false,
          is_must: true,
          column: 'register_area'
        },
        {
          name: '已完成一次有效交易',
          type: 'is_trading',
          show: false,
          is_must: true,
          column: 'is_trading'
        },
        {
          name: '金额',
          type: 'fixed_money',
          is_must: true,
          show: false,
          column: 'fixed_money'
        }
      ],
      fieldList: [
        {
          name: '新增业务字段',
          type: 'radio',
          is_must: true,
          edit: false,
          child: [
            {
              name: '姓名',
              type: 'name',
              is_must: true,
              show: false,
              column: 'name'
            },
            {
              name: '手机号',
              type: 'tel',
              is_must: true,
              show: false,
              column: 'tel'
            },
            {
              name: '授权手机号',
              type: 'register_mini_tel',
              show: false,
              is_must: true,
              column: 'register_mini_tel'
            },
            {
              name: '生日',
              type: 'register_birthday',
              show: false,
              is_must: true,
              column: 'register_birthday'
            },
            {
              name: '所在区域',
              show: false,
              type: 'register_area',
              is_must: true,
              column: 'register_area'
            },
            {
              name: '已完成一次有效交易',
              show: false,
              type: 'is_trading',
              is_must: true,
              column: 'is_trading'
            },
            {
              name: '金额',
              type: 'fixed_money',
              is_must: true,
              show: false,
              column: 'fixed_money'
            }
          ]
        }
      ]
    }
  },
  components: {},
  computed: {
    dragBorder () {
      if (this.dragIndex !== '' && this.changeIndex !== '') {
        if (this.dragIndex > this.changeIndex) {
          return {
            borderTop: '2px solid #efefef'
          }
        } else if (this.dragIndex < this.changeIndex) {
          return {
            borderBottom: '2px solid #efefef'
          }
        } else {
          return {}
        }
      } else {
        return {}
      }
    },
    dragChildBorder () {
      if (this.dragFatherIndex === '') {
        return {}
      }
      if (this.dragChildIndex !== '' && this.changeChildIndex !== '') {
        if (this.dragChildIndex > this.changeChildIndex) {
          return {
            borderTop: '2px solid #efefef'
          }
        } else if (this.dragChildIndex < this.changeChildIndex) {
          return {
            borderBottom: '2px solid #efefef'
          }
        } else {
          return {}
        }
      } else {
        return {}
      }
    }
  },
  methods: {
    addField (index) {
      if (this.radioValue === 1) {
        this.register_info.push({
          name: '新增字段',
          type: 'input',
          is_must: true,
          edit: false,
          show: true
        })
      } else {
        if (typeof index !== 'undefined') {
          if (this.fieldList[index].child) {
            this.fieldList[index].child.push({
              name: '新增字段',
              type: 'input',
              is_must: true,
              edit: false,
              show: true
            })
          }
        } else {
          this.fieldList.push({
            name: '新增业务字段',
            type: 'radio',
            is_must: true,
            edit: false,
            child: JSON.parse(JSON.stringify(this.defalutField))
          })
        }
      }
    },
    editField (index, index2) {
      if (this.radioValue === 1) {
        this.register_info[index].edit = !this.register_info[index].edit
      } else {
        if (typeof index2 !== 'undefined') {
          this.fieldList[index].child[index2].edit = !this.fieldList[index]
            .child[index2].edit
        } else {
          this.fieldList[index].edit = !this.fieldList[index].edit
        }
      }
    },
    delField (index, index2) {
      if (this.radioValue === 1) {
        this.register_info.splice(index, 1)
      } else {
        if (typeof index2 !== 'undefined') {
          this.fieldList[index].child.splice(index2, 1)
        } else {
          this.fieldList.splice(index, 1)
        }
      }
    },
    onChildDragStart (index, index2) {
      this.dragFatherIndex = index
      this.dragChildIndex = index2
    },
    onChildDragover (index, index2) {
      if (index === this.dragFatherIndex) {
        this.changeChildIndex = index2
      } else {
        this.changeChildIndex = ''
      }
    },
    onChildDragend (index) {
      // const item = this.fieldList[this.dragFatherIndex].child[
      //   this.changeChildIndex
      // ]
      const item2 = this.fieldList[this.dragFatherIndex].child[index]
      if (index !== this.changeChildIndex) {
        if (index > this.changeChildIndex) {
          this.fieldList[this.dragFatherIndex].child.splice(
            this.changeChildIndex,
            0,
            item2
          )
          this.fieldList[this.dragFatherIndex].child.splice(index + 1, 1)
        } else {
          this.fieldList[this.dragFatherIndex].child.splice(
            this.changeChildIndex + 1,
            0,
            item2
          )
          this.fieldList[this.dragFatherIndex].child.splice(index, 1)
        }
      }
      this.changeChildIndex = ''
      this.dragChildIndex = ''
      this.dragFatherIndex = ''
    },
    onDragStart (index) {
      this.dragIndex = index
    },
    onDragover (index) {
      this.changeIndex = index
    },
    onDragend (index) {
      if (this.radioValue === 1) {
        // const item = this.register_info[this.changeIndex]
        const item2 = this.register_info[index]
        if (index !== this.changeIndex) {
          if (index > this.changeIndex) {
            this.register_info.splice(this.changeIndex, 0, item2)
            this.register_info.splice(index + 1, 1)
          } else {
            this.register_info.splice(this.changeIndex + 1, 0, item2)
            this.register_info.splice(index, 1)
          }
        }
      } else {
        // const item = this.fieldList[this.changeIndex]
        const item2 = this.fieldList[index]
        if (index !== this.changeIndex) {
          if (index > this.changeIndex) {
            this.fieldList.splice(this.changeIndex, 0, item2)
            this.fieldList.splice(index + 1, 1)
          } else {
            this.fieldList.splice(this.changeIndex + 1, 0, item2)
            this.fieldList.splice(index, 1)
          }
        }
      }
      this.changeIndex = ''
    },
    async getSendPacketFormConfig (id) {
      const res = await getSendPacketFormConfig({
        activity_id: id
      })
      if (res.meta.code === 0) {
        this.radioValue = res.data.form_type
        this.isHistory = res.data.is_history
        if (res.data.form_config) {
          if (res.data.form_type === 1) {
            this.register_info = res.data.form_config
            this.register_info.map((item) => {
              if (item.type === 'input') {
                item.edit = false
              }
            })
          } else {
            this.fieldList = res.data.form_config
            this.fieldList.map((item) => {
              item.child.map((child) => {
                if (child.type === 'input') {
                  child.edit = false
                }
              })
            })
          }
        }
      }
    },
    async submit () {
      const res = await setSendPacketFormConfig({
        activity_id: this.$route.query.id,
        form_type: this.radioValue,
        form_config: this.radioValue === 1 ? this.register_info : this.fieldList
      })
      if (res.meta.code === 0) {
        this.$message.success('保存成功')
        this.$router.push({ name: 'SendPacket' })
      } else {
        this.$message.error(res.meta.msg)
      }
    }
  },
  mounted () {
    this.getSendPacketFormConfig(this.$route.query.id)
  }
}
</script>

<style lang="scss" scoped>
.container {
  font-size: 12px;
  .form-item {
    display: flex;
    align-items: center;
    padding: 10px;
    .form-name {
      margin: 0 15px;
      display: inline-block;
    }
  }
  .tree {
    .add-btn {
      margin: 15px 0;
    }
    .tree-item {
      padding: 10px;
    }
    .tree-field-name {
      display: flex;
      align-items: center;
    }
    .tree-children-content {
      padding-left: 20px;
      .tree-children-item {
        padding: 10px;
      }
    }
  }
}
</style>
